import React from "react";
import LayoutMain from "../../layouts/LayoutMain";
import Seo from "../../components/seo";
import Feature from "../../components/ui/Feature.js";
import { Row, Col, Divider, Typography, Alert } from "antd";
import {
  CheckSquareOutlined,
  EditOutlined,
  InteractionOutlined,
} from "@ant-design/icons";
import ChartSchematic from "../../components/chart/interactions/basic-schematic/Chart.js";
import DemoChoiceComponents from "../../client/examples/json/demo-choice-components.json";
import LearnMore from "../../components/sections/LearnMore.js";

const { Title, Paragraph } = Typography;

const FeaturesContentPage = () => (
  <LayoutMain>
    <Seo
      title="Features | Content"
      description="inFlow helps professional organisations create interactive decision-making tools such as flow charts, decision trees or algorithms. Improve your process and increase engagement with our world-class tools."
    />
    <Row
      justify="center"
      align="middle"
      className="heading"
      style={{ backgroundColor: "#fff" }}
    >
      <Col xs={24} sm={20} md={20} lg={16} xl={12}>
        <Title>Features</Title>
        <Title level={2} style={{ color: "white" }}>
          Content
        </Title>
      </Col>
    </Row>
    <Row justify="center" className="section">
      <Col xs={24} sm={20} md={20} lg={16} xl={12}>
        <Row>
          <Col span={24}>
            <Title
              level={2}
              style={{ marginTop: "24px", marginBottom: "36px" }}
            >
              <EditOutlined style={{ marginRight: "12px", color: "#EBD247" }} />
              Content service
            </Title>
            <Alert
              message="We offer a fully hands-off service. That means that our team
              creates, edits and keeps your charts up-to-date."
              type="success"
              closeText="Okay"
            />
          </Col>
          <Feature
            title="Chart creation"
            description="From static asset to interactive chart. Our team adds your content and gives tips for an optimal experience."
          />
          <Feature
            title="Content edits"
            description="Our team assists you when you need to update your charts. We have a quick turn-around time for edits."
          />
          <Feature
            title="Maintenance"
            description="Our code and components are regularly updated to provide the best performance."
          />
        </Row>
      </Col>
    </Row>
    <Divider dashed type={{ margin: "0" }} />
    <Row
      justify="center"
      className="section"
      style={{ backgroundColor: "#f8f9fa" }}
    >
      <Col xs={24} sm={20} md={20} lg={16} xl={12}>
        <Row>
          <Col span={24}>
            <Title
              level={2}
              style={{ marginTop: "24px", marginBottom: "36px" }}
            >
              <InteractionOutlined
                style={{ marginRight: "12px", color: "#EBD247" }}
              />
              Content types
            </Title>
          </Col>
          <Feature
            title="Rich content"
            description="Create the perfect content by using our rich text components. We support a variety of components such as styled text, tooltips, icons and others."
          />
          <Feature
            title="Widgets"
            description="Our widgets allow you to make extensive charts and processes understandadable and user-friendly. Widgets include components like modals, slideshows and video."
          />
          <Feature
            title="Customization"
            description="We create custom components and widgets on request. Sent us a message to see what's possible."
          />
        </Row>
      </Col>
    </Row>
    <Divider dashed type={{ margin: "0" }} />
    <Row
      justify="center"
      className="section"
      style={{ backgroundColor: "#f8f9fa" }}
    >
      <Col xs={24} sm={20} md={20} lg={16} xl={12}>
        <Row>
          <Col span={24}>
            <Title
              level={2}
              style={{ marginTop: "24px", marginBottom: "36px" }}
            >
              <CheckSquareOutlined
                style={{ marginRight: "12px", color: "#EBD247" }}
              />
              Choice components
            </Title>
          </Col>
          <Paragraph>
            Sometimes a button is not enough. We offer multiple choice
            components. These options allow you to create extensive tools.
          </Paragraph>
          <ChartSchematic entry={DemoChoiceComponents} firstNodeId="node1" />
        </Row>
      </Col>
    </Row>
    <Divider dashed type={{ margin: "0" }} />
    <LearnMore />
  </LayoutMain>
);

export default FeaturesContentPage;
